.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.container button {
  width: 100%;
}

.password {
  flex: 1;
  padding: 20px;
}

.deactivate-account {
  padding: 20px;
  border-top: 1px solid var(--border);
}

.deactivate-account button {
  background: var(--error-500);
  margin: 20px 0;
}
