.Toastify__toast-body {
  font-family: "Plus Jakarta Sans" !important; /* Use !important to override */
  line-height: 1.5 !important;
}

.Toastify__toast-container {
  width: 520px;
  max-width: 100%;
}

