.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  width: 100vw;
  overflow: hidden;
}

.container h1 {
  text-align: center;
  margin: 20px;
  font-size: 25px;
}

.elements-container {
  padding: 10px;
  flex: 1;
  overflow: hidden;
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.shifts-container,
.filters-container {
  width: 100%;
  position: absolute;
  top: 0;
  transition: all 200ms ease;
  padding: 10px 20px;
  height: 100%;
  overflow-y: auto;
  touch-action: pan-y !important;
}

.shifts-container *,
.filters-container * {
  touch-action: pan-y !important;
}

.shifts-container {
  left: -100%;
}

.filters-container {
  right: -100%;
}

.shifts-container.visible {
  left: 0;
}

.filters-container.visible {
  right: 0;
}

.shift-container {
  height: 50vh;
  width: calc(100%);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 15px 1px rgba(173, 173, 173, 0.2);
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}

.empty-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-container p {
  font-size: 14px;
  text-align: center;
  color: var(--secondary-300);
}

.loading-skeleton {
  height: 100%;
  position: relative;
}

.spinner {
  color: var(--orange);
  font-size: 30px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
