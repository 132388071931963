.shiftly-logo {
  width: 35px;
  margin-right: 30px;
}

.container span {
  color: var(--orange);
}

.container h3 {
  font-size: 28px;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
