.cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}
