.multi-select-component {
  width: 100%;
}

.input-wrapper {
  width: 100%;
  position: relative;
  padding: 0 3px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-container {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 7px;
  padding: 7px 16px;
  background: var(--input-background);
  border: 0.5px solid var(--secondary-400, #94a3b9);
  margin: 5px 0px;
  box-sizing: border-box;
  position: relative;
  cursor: text;
}

.select-wrapper {
  border: 0.5px solid var(--secondary-400, #94a3b9);
  background: var(--input-background);
  border-radius: 7px;
  width: 100%;
  padding: 2px 0px;
  margin: 6px 1px;
}

.input {
  width: 100% !important;
  border: none;
  outline: none;
  font-size: 16px;
  background: none;
  box-sizing: border-box;
  line-height: 2;
}

.input::placeholder {
  color: var(--secondary-300, #cdd9e3) !important;
  font-size: 16px;
}

.input-container:focus-within {
  outline: 1px solid var(--secondary-400, #94a3b9);
  box-sizing: border-box;
}

.date-input {
  -webkit-appearance: none;
  appearance: none;
  text-align: left !important;
  color: black !important;
  font-family: inherit !important;
  font-size: 16px;
  width: auto !important;
}

.date-input::-webkit-calendar-picker-indicator {
  display: none;
}

.date-input::-webkit-inner-spin-button,
.date-input::-webkit-clear-button {
  display: none;
}

.date-placeholder {
  color: var(--secondary-300, #cdd9e3) !important;
  font-size: 16px;
}

.icon-padding {
  padding: 0 30px;
}

.chevron {
  transform: rotate(0deg);
  transition: all 200ms ease;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: center;
}

.chev-open {
  transform: translateY(-50%) rotate(180deg);
}

.error-container {
  background: var(--error-light);
  border-radius: 2px;
  padding: 8px;
}

.error .input {
  color: var(--error-dark);
}

.error {
  border: 1px solid var(--error-dark) !important;
  background: rgba(255, 208, 208, 0.15) !important;
}

.error:focus-within {
  outline: 1px solid var(--error-dark);
  box-sizing: border-box;
}

.label {
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
}

.date-label {
  font-weight: 500;
  margin-bottom: 5px;
}

.error .date-label {
  color: var(--error-dark);
}

.message {
  font-weight: 500;
  color: var(--error-dark);
}

.link {
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: auto;
  top: -30px;
  right: 0;
  left: auto;
  color: var(--blue);
}

.disabled {
  color: rgb(170, 170, 170) !important;
  background: var(--disabled-background) !important;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.icon.right {
  right: 15px;
}

.icon.left {
  left: 15px;
}

.tagsContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 8px;
}

/* Dropdown */

.placeholder {
  color: #aaa;
}

.search-input {
  height: 100%;
  width: 100%;
  font-size: 16px;
}

.dropdown-menu {
  position: absolute;
  top: 95%;
  left: 0;
  right: 0;
  border: 1px solid var(--border);
  background-color: white;
  z-index: 1000;
  border-radius: 0 0 10px 10px;
  box-shadow: var(--box-shadow);
  overflow-y: auto;
  margin-top: 4px;
  transition: all 0.2s ease;
  height: 0;
  opacity: 0;
}

.isOpen {
  height: auto;
  max-height: 180px;
  opacity: 1;
}

.mobile-isOpen {
  max-height: 280px;
}

.option {
  padding: 10px 12px;
  font-size: 16px;
  color: var(--secondary-900);
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.option:hover {
  background-color: var(--secondary-100);
}

.focused-option {
  background-color: var(--secondary-100);
}

.selected-option {
  background-color: var(--secondary-200);
  font-weight: 500;
}

.special-btn {
  position: absolute;
  left: 7px;
  bottom: 5px;
  background: var(--secondary-100);
  color: var(--secondary-900);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  transform: translateX(-100%);
  border: 0.5px solid var(--secondary-400, #94a3b9);
  border-radius: 7px 0 0 7px;
  cursor: pointer;
}
