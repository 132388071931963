.wrapper {
  margin-bottom: 20px;
  position: relative;
  padding: 0 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.checkbox {
  border: 1px solid var(--border);
  width: 25px;
  height: 25px;
  border-radius: 4px;
  margin-right: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox.checked {
  background: var(--blue);
}
.label span {
  color: var(--orange);
  margin-left: 10px;
}

/* Error */

.error-container {
  background: var(--error-light);
  border-radius: 5px;
  padding: 5px 10px;
}

.message {
  font-weight: 500;
  color: var(--error-dark);
  font-size: 14px;
}
