.container {
  background: var(--secondary-100);
  position: relative;
  min-height: 80px;
}

.mobile-container {
  min-height: 60px;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  height: 100%;
}

.mobile-tabs {
  justify-content: center;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
  transition: background 0.3s;
  width: 180px;
  white-space: nowrap;
  font-size: 16px;
}

.mobile-tab {
  width: 100%;
}

.tab.active {
  background: white;
  color: var(--orange);
  font-weight: 600;
}

.slider-container {
  width: 100%;
  height: 3px;
  position: relative;
  background: var(--secondary-200);
}

.slider {
  position: absolute;
  top: 0;
  height: 3px;
  background: var(--orange);
  border-radius: 20px;
  transition: all 200ms ease;
}

