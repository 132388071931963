.container {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}

.container:last-child {
  margin-bottom: 0;
}

.bubble {
  padding: 8px 15px;
  max-width: 500px;
  border-radius: 15px;
  position: relative;
  box-shadow: var(--box-shadow);
}

.sending {
  background: var(--secondary-900);
  color: white;
  margin-left: auto;
  border-radius: 15px 15px 0 15px;
}

.receiving {
  background: white;
  color: var(--secondary-900);
  margin-right: auto;
  border-radius: 15px 15px 15px 0;
}

.sent_time {
  font-size: 12px;
  color: var(--secondary-400);
  margin-top: 8px;
  margin-bottom: 10px;
}

.sent_time.left {
  text-align: left;
}

.sent_time.right {
  text-align: right;
}

.shift-container {
  padding: 0 20px;
}
