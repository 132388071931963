.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 115;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  overflow: scroll;
}
