.wrapper {
  width: 100%;
  position: relative;
  padding: 0px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.container {
  border-radius: 7px;
  padding: 7px 3px;
  margin: 5px 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container.disabled {
  background: var(--disabled-background);
  color: rgb(170, 170, 170);
}

.increment-unit-input {
  margin: 0 20px;
}

.input {
  width: 50px;
  text-align: center;
  border: none;
  outline: none;
  font-size: 16px;
  background: none;
  box-sizing: border-box;
  line-height: 2;
  color: var(--secondary-900);
}

.increment-btn {
  background: var(--secondary-900);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 50px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.increment-btn:disabled {
  background: var(--disabled-background);
  color: rgb(170, 170, 170);
  cursor: not-allowed;
}

.unit {
  margin-right: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.label {
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
  font-size: 14px;
}

.label span {
  color: var(--orange);
}

.link {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-900);
}

/* Error */

.error-container {
  background: var(--error-light);
  border-radius: 5px;
  padding: 5px 10px;
}

.message {
  font-weight: 500;
  color: var(--error-dark);
  font-size: 14px;
}
