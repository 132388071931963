.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: white;
  z-index: 102;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.container p {
  font-size: 14px;
}

.subtext {
  border-bottom: 1px solid var(--border);
  padding: 20px;
  text-align: center;
}

.shifts {
  flex: 1;
  padding: 10px;
  border-bottom: 1px solid var(--border);
  overflow: scroll;
  touch-action: pan-y !important;
  width: 100%;
}

.shifts * {
  touch-action: pan-y !important;
}

.no-shifts {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.no-shifts p {
  font-size: 16px;
  color: var(--secondary-600);
  margin: 20px auto;
  font-weight: 600;
  width: 75%;
}

.shift-container {
  padding: 10px;
  border-bottom: 1px solid var(--border);
}

.pretty-date {
  margin-bottom: 10px;
}

.loc-pos {
  font-weight: 600;
  color: var(--orange);
}

.loc-pos p {
  font-size: 16px;
}

.amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.amount span {
  font-weight: 100;
}

.super {
  margin-bottom: 10px;
}

.total-due {
  padding: 20px;
  width: 100%;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}

.total span {
  font-weight: 100;
}

.total-super p {
  font-style: italic;
}

.contact {
  padding: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 40px;
}
