.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100vh;
  position: fixed;
  width: 100vw;
  background: white;
  z-index: 102;
  transition: all 0.3s ease;
}

.swiper-container {
  width: 100%;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.slide-img {
  height: 45vh;
  width: 90vw;
  border-radius: 25px;
  overflow: hidden;
  background: var(--orange);
}

.slide-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.slide-text {
  margin: 20px auto;
}

.slide-text p {
  width: 90%;
  margin: 20px auto;
}

.slide-text span {
  color: var(--orange);
}

.button-container {
  width: 100%;
  padding: 20px;
}

.button {
  width: 100%;
}

.nav-text {
  margin: 20px 0;
  text-align: center;
}

.nav-text span {
  color: var(--orange);
  font-weight: 500;
}
