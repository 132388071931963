.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shiftly-logo {
  width: 35px;
}

.nav-container {
  position: absolute;
  top: 5px;
  right: 65px;
}

.steps-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
}

.step {
  position: relative;
  z-index: 2;
  margin: 0 60px 0 5px;
  cursor: pointer;
  color: var(--secondary-500);
}

.step-active {
  color: var(--orange);
}

.step:last-child {
  margin-right: 5px;
}

.step-past {
  color: var(--orange);
}

.progress-bar {
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 25px;
  background-color: var(--secondary-100);
  bottom: 0px;
}

.hidden-nav {
  bottom: auto;
  top: 100ppx;
}

.bar {
  transition: all 0.3s ease;
  height: 100%;
  border-radius: 25px;
  background-color: var(--primary-500);
  width: 10%;
}

.steps-content-container {
  display: flex;
  overflow-x: hidden;
  min-height: 500px;
  width: 100%;
}

.step-content {
  width: 100%;
  overflow-x: hidden;
  flex-grow: 0;
  flex-shrink: 0;
}

.step-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  pointer-events: none;
}

.step-footer button {
  pointer-events: all;
}
