.container {
  touch-action: none;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
  overflow: hidden;
}

.container * {
  touch-action: none;
}
