.heading {
  position: relative;
  width: 100%;
  padding: 40px 20px 20px 20px;
  text-align: center;
  border-bottom: 1px solid var(--border);
}

.left {
  text-align: left;
}
.close-icon {
  position: absolute;
  top: 55%;
  left: 15px;
}

.larger-icon {
  font-size: 20px;
}