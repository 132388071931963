.container {
  display: flex;
  flex-direction: column;
  touch-action: pan-y !important;
  height: 100%;
  padding-bottom: 100px;
}

.container * {
  touch-action: pan-y !important;
}

.content {
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.picture-container {
  border-radius: 50%;
  width: 130px;
  min-height: 130px;
  height: 130px;
  overflow: hidden;
  margin: 20px 0;
  border: 3px solid var(--secondary-900);
}

.info-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
}

.info-item {
  text-align: center;
  width: 100%;
  margin: 20px 0;
}

.info-item p {
  font-size: 16px !important;
}

.bio-container {
  padding: 0 20px;
  width: 100%;
}

.bio-heading {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Empty State */

.empty-container {
  padding: 10px 0;
}

.empty-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.empty-box {
  background: var(--secondary-200);
  border-radius: 8px;
  min-width: 80px;
  height: 80px;
  margin-right: 20px;
}

.empty-content p {
  font-size: 14px;
  font-weight: 600;
}

.empty-btn button {
  width: 100%;
}
