.payments {
  padding: 10px;
  touch-action: pan-y !important;
}

.payments * {
  touch-action: pan-y !important;
}

.no-payments {
  text-align: center;
  width: 75%;
  margin: 20px auto;
}

.no-payments p {
  color: var(--secondary-500);
  font-weight: 600;
}
