.wrapper {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  padding: 0 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.switch {
  position: relative;
  width: 50px;
  height: 30px;
  border-radius: 30px;
  background-color: #ccc;
  transition: background-color 0.3s;
}

.switch.checked {
  background-color: var(--orange);
}

.switch-button {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switch.checked .switch-button {
  transform: translateX(20px);
}

.switch-details {
  margin-left: 10px;
}

.disabled {
  opacity: 0.4;
}

.label span {
  color: var(--orange);
  margin-left: 10px;
}

/* Error */

.error-container {
  background: var(--error-light);
  border-radius: 5px;
  padding: 5px 10px;
}

.message {
  font-weight: 500;
  color: var(--error-dark);
  font-size: 14px;
}
