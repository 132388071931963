.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-container {
  width: 30%;
  padding: 10px;
  position: relative;
}

.image {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.checks {
  position: absolute;
  bottom: 20px;
  right: 10px;
  border-radius: 50%;
  background: var(--secondary-900);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.content {
  padding: 0 15px;
}
