.container {
  padding: 10px;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status {
  color: var(--success);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}

.status p {
  margin-left: 15px;
  font-size: 14px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  margin-left: 10px;
  border: 1px solid var(--secondary-900);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}
