/* 
   For smaller devices like iPhone 5/SE 
   Portrait: 320x568px
*/
@media only screen and (max-device-width: 374px) {
  .XXXShidden {
    display: none !important;
  }
}

@media only screen and (max-device-width: 375px) {
  .XXShidden {
    display: none !important;
  }
}
