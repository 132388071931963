.tabs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--box-shadow);
}

.tab-item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neautral-500);
  padding: 10px;
}

.tab-item p {
  margin: 0 10px;
}

.tab-item svg path {
  fill: var(--secondary-500);
}

.tab-item.active p {
  color: var(--orange-dark);
  font-weight: bold;
}

.tab-item.active svg path {
  fill: var(--orange);
}

.tab-slider-container {
  width: 100%;
  position: relative;
  height: 2px;
  background: var(--border);
  box-shadow: 0px 4px 20px 4px rgba(187, 186, 186, 0.25);
}

.tab-slider {
  height: 2px;
  position: absolute;
  top: 0;
  background: var(--orange);
  transition: all 0.3s ease;
}
