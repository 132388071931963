.file-uploader-input {
  display: none;
}

.upload-container {
  margin-bottom: 20px;
}

.label {
  font-weight: 500;
  margin-bottom: 15px;
  white-space: nowrap;
  font-size: 14px;
  display: block;
}

.label span {
  color: var(--orange);
}

.upload-button {
  width: 100%;
}
