.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 98;
  transition: all 400ms ease;
  pointer-events: none;
  opacity: 0;
}

.visible {
  pointer-events: all;
  opacity: 1;
}
