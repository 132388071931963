.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.icon.right {
  right: 15px;
}

.icon.left {
  left: -5px;
}

.hasIcon-left {
  padding-left: 20px;
}

.hasIcon-right {
  padding-right: 20px;
}
