.container {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px 30px 30px;
  z-index: 100;
  box-shadow: var(--box-shadow);
  background-color: white;
  font-size: 22px;
  touch-action: none !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.footer-next,
.footer-prev {
  width: 20%;
  display: flex;
  align-items: center;
}

.footer-next {
  justify-content: flex-end;
}

.footer-prev {
  justify-content: flex-start;
}

.container * {
  touch-action: none !important;
}

.container button {
  min-width: 60%;
}
