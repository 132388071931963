.container {
  padding: 20px;
}

.save-btn {
  margin: 20px 0 10px 0;
  width: 100%;
}

.upload-btn {
  width: 100%;
  margin: 20px 0;
}
