.container {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 4px 15px 1px rgba(173, 173, 173, 0.2);
  background: white;
  height: 95px;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px 30px 30px;
  width: 100%;
}

.active p {
  color: var(--orange-dark);
  font-weight: bold !important;
}

.active svg path {
  fill: var(--orange);
}

.nav-item-content {
  position: relative;
}
.badge {
  position: absolute;
  top: -10px;
  right: -15px;
  background: var(--orange);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
