/* General */
.container {
  position: fixed;
  height: 90vh;
  width: 100%;
  top: calc(100% + 50px);
  left: 0;
  background: white;
  box-shadow: var(--box-shadow);
  z-index: 101;
  border-radius: 15px 15px 0 0;
  transition: 500ms all ease;
}

.visible {
  top: 10%;
}

.animate-hide {
  top: calc(100% + 50px);
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.scroll-container {
  flex: 1;
  overflow: scroll;
  touch-action: pan-y !important;
  padding-bottom: 100px;
  transition: 500ms all ease;
}

.scroll-container * {
  touch-action: pan-y !important;
}

.scroll-container::-webkit-scrollbar,
.confirm-container::-webkit-scrollbar {
  display: none;
}

.overlay {
  z-index: 101;
}
/* Shift Header */

.logo-container {
  position: absolute;
  left: 50%;
  top: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid white;
}

.logo-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.rating-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rating-container p {
  margin-left: 5px;
}

.title-container {
  text-align: center;
  margin: 20px;
  padding: 0 0 20px 0;
  border-bottom: 1px solid var(--border);
}

.title-container p {
  margin: 10px 0;
  font-size: 14px;
}

/* Shift Info */
.shift-details {
  text-align: center;
  border-bottom: 1px solid var(--border);
  margin: 20px;
}

.date {
  margin-bottom: 20px;
}

.clean-format-info {
  text-align: left;
}

.clean-format-info p {
  font-size: 14px;
  color: var(--secondary-500);
  margin: 10px 0;
  font-weight: 500;
}

.clean-format-info p span {
  color: var(--orange);
}

.info-block {
  margin: 10px 0;
  padding: 0 10px;
  text-align: left;
  color: var(--secondary-800) !important;
}

.info-block-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 0;
}

.info-block-icon {
  font-size: 20px;
  width: 30px;
}
.info-block-title {
  font-weight: 600;
}
.info-block-content p {
  margin-left: 20px;
  font-size: 14px;
}

.qualification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  color: var(--secondary-900);
}

.qualification p {
  margin: 5px 10px;
  font-size: 14px;
}

.info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.info-item {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.info-icon {
  color: white;
  background-color: var(--primary-700);
  box-shadow: var(--box-shadow);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.info-amount p {
  font-size: 14px;
  margin: 10px 0;
  font-weight: 500;
  white-space: nowrap;
}

.info-amount p span {
  font-size: 12px;
}

/* Shift Position */
.position-details h6 {
  padding: 0 20px;
}

.position-details p {
  font-size: 14px;
  margin-top: 10px;
  line-height: 1.8;
  padding: 0 20px;
}

.responsibilities {
  margin-top: 20px;
}

.responsibilities ul {
  margin-top: 10px;
  margin-bottom: 40px;
}

.notes {
  margin-bottom: 40px;
}

.responsibilities li {
  margin-left: 40px;
}

.location-images {
  margin: 20px 0 20px 0;
}

.position-img {
  height: 100%;
  height: 30vh;
  margin-left: 20px;
  padding-bottom: 40px;
}

.last {
  margin-right: 20px;
}

.position-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

/* Shift Location */
.location-container {
  padding: 0 20px;
}

.location-header,
.location-address-heading,
.location-header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.location-header {
  margin-bottom: 10px;
}

.location-header-logo {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.location-header-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.location-description p {
  line-height: 1.5;
  font-size: 14px;
  margin: 10px 0;
}

.location-address {
  margin: 20px 0;
}

.location-address-heading-details p {
  font-size: 14px;
  color: var(--secondary-500);
  margin-top: 10px;
}

.location-map-container {
  height: 200px;
  margin: 20px 0;
}

.location-socials {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social-icon {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.social-icon img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 10px 0;
}

.social-icon p {
  font-weight: 600;
  font-size: 14px;
}

.social-icon .icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-800);
  color: white;
  border-radius: 50%;
  margin: 10px 0;
  font-size: 23px;
}

/* More Shifts */

.more-shifts-container {
  margin: 20px 0;
  width: 100%;
  overflow: hidden;
}

.more-shifts-container h4 {
  margin: 20px;
}

.more-shifts {
  display: flex;
  width: auto;
  overflow-x: auto; /* Enable horizontal scrolling */
  flex-wrap: nowrap;
  touch-action: auto !important;
}

.more-shifts * {
  touch-action: auto !important;
}

.shiftcard-container {
  min-width: 200px;
  position: relative;
  height: 130px;
  background: var(--secondary-100);
  border-radius: 15px;
  margin: 0 5px;
  padding: 20px 0;
  overflow: hidden;
}

.shiftcard-container:first-child {
  margin-left: 20px;
}

.shiftcard-container:last-child {
  margin-right: 40px;
}

.shiftcard-plus {
  position: absolute;
  top: 15px;
  right: 15px;
}

.shiftcard-wage {
  background: var(--secondary-900);
  color: white;
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 20px;
  padding: 5px;
  border-radius: 0 5px 5px 0;
}

.shiftcard-details {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.shiftcard-position {
  font-size: 18px;
  font-weight: 500;
  margin: 0 !important;
  margin-bottom: 5px !important;
}

.shiftcard-details p {
  margin: 0;
  font-weight: 500;
}

.shiftcard-details p span {
  font-size: 12px;
  margin-left: 10px;
  font-weight: 100;
}

/* Contact Employer */

.employer-container {
  margin: 40px 20px;
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  padding: 20px;
  position: relative;
}

.employer-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 15px;
  border: 1px solid var(--primary-500);
}

.employer-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.employer-container p {
  margin: 10px 0;
  font-size: 14px;
}

.employer-send-button {
  margin-top: 20px;
  width: 100%;
}

/* Your Profile */

.profile-container {
  margin: 40px 0px;
  padding: 20px;
}

.profile-heading {
  text-align: center;
  margin-bottom: 60px;
}

.profile-card {
  border-radius: 15px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  position: relative;
}

.profile-card-image {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid var(--orange);
}

.profile-card-rating {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-card-rating p {
  margin-left: 5px;
}

.profile-card-heading {
  padding-top: 60px;
  text-align: center;
  margin-bottom: 20px;
}

.profile-card-bio p {
  margin: 20px 0;
  line-height: 1.5;
  text-align: center;
}

.profile-card-cert {
  padding: 20px 0;
  border-bottom: 1px solid var(--border);
}

.profile-card-cert h5 {
  margin: 15px 0;
}

.profile-cert {
  margin: 30px 0;
}
.profile-cert:last-child {
  margin-bottom: 0;
}

.profile-cert-title {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.profile-cert-description {
  font-size: 14px;
  line-height: 1.5;
}

.profile-card-button {
  margin: 20px 0 0 0;
  width: 100%;
}

.profile-danger-text {
  color: var(--error-500);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.profile-incomplete-sub-text {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}

/* Cancel */

.cancel-shift {
  padding: 10px;
  margin: 20px;
}

.cancel-shift h4 {
  font-size: 20px;
}

.cancel-shift p {
  margin: 20px 0;
  font-size: 14px;
}

.cancel-btn {
  width: 100%;
}
/* Footer Nav */

.footer-container {
  margin-top: 40px;
}

.footer-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
  padding: 15px 20px;
}

/* Confirming */

.confirm-container {
  height: calc(100% - 155px);
  overflow: scroll;
  touch-action: pan-y !important;
  transition: 500ms all ease;
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  z-index: 101;
  transform: translateX(100%);
  padding: 0 20px;
}

.confirm-container * {
  touch-action: pan-y !important;
}

.confirming {
  transform: translateX(-100%);
}

.confirming-visible {
  transform: translateX(0%);
}

.confirm-wrapper {
  position: relative;
  padding-bottom: 20px;
}

.confirm-heading {
  text-align: center;
  margin: 20px auto;
  width: 75%;
}

.confirm-heading h2 {
  margin: 10px 0;
}

.confirm-heading {
  margin-bottom: 30px;
}

.confirm-overview-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.confirm-overview-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.confirm-overview-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.overview-address {
  color: var(--secondary-500);
  font-size: 14px;
  margin-top: 5px;
}

.confirm-position {
  margin: 20px 0;
}

.confirm-date {
  margin: 20px 0;
}

.confirm-date h5 {
  margin: 5px 0;
}

.confirm-pay-container,
.confirm-card {
  margin: 20px 0;
  border-radius: 15px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  position: relative;
}

.confirm-amount-container {
  padding: 10px;
  background: var(--secondary-100);
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm-amount-container p {
  font-weight: 500;
  font-size: 14px;
}

.confirm-amount-container p span {
  font-size: 12px;
}

.break {
  font-size: 12px;
  color: var(--secondary-700);
  margin-top: 10px;
}

.confirm-amount-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10px;
}

.confirm-amount-total h5 span {
  font-size: 14px;
  font-weight: 100;
  margin-left: 10px;
}

.confirm-arrow-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.confirm-card h5 {
  margin: 10px 0;
}

.confirm-card p {
  font-size: 14px;
}

.skeleton-details {
  margin: 10px 0;
}
