.container {
  padding: 20px;
}

/* What */

.popular-searches {
  margin-top: 5vh;
}
.pop-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 5px;
}
.pop-heading p {
  font-weight: 600;
}

.view-all-arrow {
  margin-left: 5px;
}

.pop-labels {
  padding: 10px 0;
}

.label-button {
  background: var(--primary-50) !important;
  color: var(--primary-900) !important;
  margin: 5px;
  padding: 10px 16px !important;
  font-size: 12px !important;
}

.label-button p {
  font-size: 12px !important;
}

.selected-labels {
  margin-top: 20px;
  border-top: 1px solid var(--secondary-200);
  padding: 10px 0;
}

.label-button-selected {
  background: var(--secondary-50) !important;
  color: var(--secondary-900) !important;
  margin: 5px;
  padding: 10px 16px !important;
  font-size: 12px !important;
}

.label-button-selected p {
  font-size: 12px !important;
}

.no-more-positions {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  color: var(--secondary-500);
}

.all-groups {
  padding: 20px;
}

/* Where */
.increments {
  margin-top: 30px;
}

/* When */
.date-content-container {
  margin-top: 40px;
}

.date-radio-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25vw;
  padding: 10px 0;
}

.date-radio-card h6 {
  margin: 15px 0;
}
.date-radio-card {
  font-size: 20px;
}

/* Wage */
.wage {
  margin-top: 20px;
}