.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.numbers-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  box-shadow: var(--box-shadow);
  background: var(--secondary-200);
  color: white;
  margin: 0 10px;
  transition: all 0.3s ease;
}

.number.active {
  background: var(--secondary-900);
}

.progress-container {
  width: 100%;
  height: 4px;
  border-radius: 25px;
  bottom: 0px;
}

.bar {
  transition: all 0.3s ease;
  height: 100%;
  border-radius: 25px;
  background-color: var(--secondary-900);
  width: 10%;
}

.content-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.step-content {
  width: 100%;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
