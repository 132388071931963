.container {
  padding: 0 20px;
}

.location-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0;
}

.location-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.location-details {
  margin: 0 10px;
  width: 60%;
  margin-right: auto;
}

.location-details h4 {
  font-size: 16px;
  margin-bottom: 8px;
}

.location-details p {
  font-size: 14px;
  color: var(--secondary-400);
}

.pin-container {
  box-shadow: var(--box-shadow);
  border: 3px solid var(--warning-500);
  padding: 20px;
  border-radius: 15px;
  text-align: center;
}

.time h3 {
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.time p {
  font-size: 14px;
  color: var(--secondary-500);
}

.shift-details {
  margin: 10px 0;
  color: var(--orange);
}

.shift-details p {
  font-size: 14px;
  font-weight: 500;
}

.pin {
  margin-top: 20px;
}

.pin p {
  font-weight: 600;
}

.pin-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.pin-input {
  border-radius: 10px;
  background: var(--secondary-200);
  margin: 5px;
  width: 60px;
  height: 70px;
  text-align: center;
  font-size: 35px;
}

.sign-on {
  width: 90%;
  margin: 20px auto;
}

.finished-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.finished-buttons button {
  margin: 10px 0;
  width: 100%;
}