

  .shift-card {
    border-radius: 15px;
    padding: 20px;
    box-shadow: var(--box-shadow);
    margin: 10px 0;
    background: white;
  }
  
  .shift-card h4 {
    font-size: 18px;
    margin-bottom: 8px;
  }
  
  .shift-card p span {
    color: var(--orange);
    font-size: 14px;
    margin-right: 10px;
  }
  
  .shift-location {
    margin: 10px 0;
    font-weight: 600;
  }
  