.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-wrapper {
  flex: 1;
  overflow-y: auto;
}

@media (max-width: 821px) {
  .container {
    padding-top: 0px;
  }
}
