.react-calendar {
  border: none;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 0 10px;
  padding-bottom: 10px;
}

.react-calendar * {
  font: var("Plus Jakarta Sans");
  color: black;
}

.react-calendar__tile {
  border-radius: 5px !important;
  position: relative;
  border: 1px solid transparent !important ;
}

.react-calendar__tile--now {
  background-color: var(--secondary-200);
}

.react-calendar__tile--range {
  border-radius: 0 !important;
  border-top: 1px solid var(--primary-700) !important;
  border-bottom: 1px solid var(--primary-700) !important;
  background-color: var(--primary-50) !important;
}

.react-calendar__tile--rangeStart {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-left: 1px solid var(--primary-700) !important;
}

.react-calendar__tile--rangeEnd {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-right: 1px solid var(--primary-700) !important;
}

.react-calendar__tile--rangeEnd abbr {
  color: var(--orange);
}

.react-calendar__tile--now abbr {
  color: var(--blue);
}

.react-calendar__tile--active abbr {
  color: var(--orange);
}

.react-calendar__navigation__arrow {
  font-size: 25px !important;
  font-weight: 100;
}

.react-calendar__navigation button:disabled,
.react-calendar--selectRange .react-calendar__tile--hover,
.react-calendar__tile--hasActive {
  background-color: white;
}

.react-calendar__tile:enabled:hover {
  background-color: var(--primary-50);

}

.react-calendar__tile:disabled {
  background-color: var(--secondary-50);
  border-radius: 0 !important;
}
