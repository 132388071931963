.container {
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  touch-action: pan-y !important;
}

.container * {
  touch-action: pan-y !important;
}

.shifts {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
}

.no-shifts {
  padding: 40px 0px 20px 0px;
}

.no-shifts h3 {
  margin-bottom: 10px;
}

.no-heading {
  width: 100%;
}

.no-image {
  background: var(--secondary-200);
  border-radius: 15px;
  margin: 20px 0;
  padding: 10px;
}

.no-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-button button {
  width: 75%;
}
