.container {
  position: absolute;
  bottom: 90px;
  left: 0;
  width: 100%;
  z-index: 100;
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
  background: white;
}

.link p {
  font-weight: 600;
}
