.container {
  border-radius: 15px 15px 0 0;
  box-shadow: var(--box-shadow);
  z-index: 102;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.location-logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 90px;
  width: 90px;
  overflow: hidden;
    border: 3px solid var(--secondary-900);
}

.heading {
  margin: 20px 0;
  text-align: center;
  padding: 20px;
  padding-bottom: 0;
}

.rating {
  font-size: 14px;
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--warning-500);
}

.rating span {
  display: inline-block;
  margin-right: 10px;
  color: var(--secondary-900);
  font-weight: 600;
}

.stars {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 40px 0;
}

.star {
  font-size: 30px;
  color: var(--secondary-300);
  margin: 0 10px;
}

.star-active {
  color: var(--warning-500);
  font-size: 30px;
  margin: 0 10px;
}

.button-container {
  margin: 20px 0;
}

.button-container button {
  width: 100%;
}
