.cert-tabs-container {
  position: relative;
}

.cert-tab {
  padding: 30px 20px;
  border-bottom: 1px solid var(--border);
  position: relative;
  z-index: 2;
  background: white;
}

.cert-tab h4 {
  padding-bottom: 10px;
}

.cert-desc {
  line-height: 1.5;
  font-size: 16px;
  margin-bottom: 10px;
}

.cert-tab .cert-subtext {
  font-weight: 600;
  font-size: 14px;
  color: var(--secondary-500);
}

.edit-tab {
  position: absolute;
  top: 35px;
  right: 10px;
}

.tab-delete-container {
  position: absolute;
  border-radius: 15px;
  top: 40px;
  left: 10px;
  width: 100px;
  height: calc(100% - 80px);
  background: var(--error);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  z-index: 1;
  transform: translateX(1px);
}
