* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}

html {
  background: var(--orange);
}

body {
  background: white;
  overflow: hidden;
}

button,
textarea,
input {
  background: none;
  padding: none;
  outline: none;
  border: none;
}

input,
textarea {
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  line-height: 2;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--secondary-300, #cdd9e3) !important;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.toast-container {
  bottom: 40px !important;
}
