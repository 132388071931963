/* Typography classes */
h1 {
  font-size: clamp(28px, 8.2, 32px);
  font-weight: 600;
}

h2 {
  font-size: clamp(18px, 6.6vw, 26px);
  font-weight: 600;
}

h3 {
  font-size: clamp(16px, 5.6vw, 22px);
  font-weight: 600;
}

h4 {
  font-size: clamp(14px, 5.1vw, 20px);
  font-weight: 600;
}

h5 {
  font-size: clamp(13px, 4.1vw, 18px);
  font-weight: 600;
}

h6 {
  font-size: clamp(12px, 3.58vw, 16px);
  font-weight: 600;
}

/* p: Paragraph text. */
p {
  font-size: clamp(12px, 4.1vw, 16px);
  font-weight: 400;
}

.large {
  font-size: clamp(14px, 4.6vw, 18px);
}

.medium {
  font-size: clamp(12px, 3.6vw, 16px);
}

.small {
  font-size: clamp(10px, 3.5vw, 14px);
}

.caption-large {
  font-size: clamp(12px, 3.5vw, 14px);
}

.caption-medium {
  font-size: clamp(11px, 3.3vw, 13px);
}

.caption-small {
  font-size: clamp(10px, 3vw, 12px);
}

.footer {
  font-size: clamp(10px, 2.5vw, 12px);
}
