.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  background: white;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  padding: 20px;
  width: 70vw;
  border-radius: 5px;
  max-width: 1200px !important;
}

.tablet-modal {
  transform: translate(-50%, -50%);
  min-width: 85vw;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
}

.modal-header svg {
  width: 20px;
  height: 100%;
  cursor: pointer;
}

.close-icon {
  position: absolute;
  top: -20px;
  right: 20px;
}

.mobile-close-icon {
  top: -10px;
}
.label {
  font-weight: 600;
  font-size: 22px;
}

.modal-divider {
  border-bottom: 1px solid var(--border);
}

.modal-content {
  max-height: 73vh;
  overflow: scroll;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
}
/* Mobile */
@media (max-width: 768px) {
  .modal-header {
    min-height: 40px;
  }

  .label {
    font-size: 16px;
  }
}
