.container {
  position: fixed;
  bottom: 160px;
  left: 10px;
  width: calc(100% - 20px);
  background: var(--greyscale-50);
  border-radius: 15px;
  padding: 20px;
}

.wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.close-icon {
  font-size: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.status {
  color: var(--success);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.status p {
  margin-left: 15px;
}

.title {
  margin: 10px 0;
}

.content p {
  font-size: 14px;
  margin: 10px 0;
}

.content p span {
  font-size: 12px;
}

.subtext {
  font-weight: 600;
}

.download-btn {
  width: 100%;
  margin: 10px 0;
}
