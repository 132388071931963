.container,
.wrapper {
  width: 100%;
}

.chevron {
  transform: rotate(0deg);
  transition: all 200ms ease;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: center;
}

.chev-open {
  transform: translateY(-50%) rotate(180deg);
}

.placeholder {
  color: #aaa;
}

.input {
  width: 100%;
  height: 100%;
}

.search-input {
  height: 100%;
  width: 100%;
  font-size: 16px;
}

.dropdown-menu {
  position: absolute;
  top: 95%;
  left: 0;
  right: 0;
  border: 1px solid var(--border);
  background-color: white;
  z-index: 101;
  border-radius: 0 0 10px 10px;
  box-shadow: var(--box-shadow);
  overflow-y: auto;
  margin-top: 4px;
  transition: all 0.2s ease;
  height: 0;
  opacity: 0;
}

.isOpen {
  height: auto;
  max-height: 180px;
  opacity: 1;
}

.mobile-isOpen {
  max-height: 280px;
}

.option {
  padding: 10px 12px;
  font-size: 16px;
  color: var(--secondary-900);
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.option:hover {
  background-color: var(--secondary-100);
}

.focused-option {
  background-color: var(--secondary-100);
}

.selected-option {
  background-color: var(--secondary-200);
  font-weight: 500;
}

.special-btn {
  position: absolute;
  left: 0px;
  bottom: -1px;
  background: var(--secondary-100);
  color: var(--secondary-900);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  transform: translateX(-100%);
  border: 0.5px solid var(--secondary-400, #94a3b9);
  border-radius: 7px 0 0 7px;
  cursor: pointer;
}
